import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';

function Privacy() {
  return (
    <div className='bottom-div'>
      <div className='app-wrapper app-container'>
        <NavigationBar />
        <div className="min-h-screen">
          {/* Hero Section */}
          <div className="flex flex-col items-center justify-center py-12">
            <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
            <p className="text-white-600">Last updated: November 14, 2024</p>
          </div>

          {/* Main Content */}
          <div className="max-w-4xl mx-auto px-4 py-16">
            {/* Information Collected Section */}
            <section>
              <h2 className="text-2xl font-semibold mb-6">Information Collected</h2>
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-medium mb-3">When you use our service</h3>
                  <p className="text-white-700">
                    When you visit our website, we track your usage, including statistics such as which pages you 
                    visit and how much time you spend on them. This information may include, but is not limited to:
                  </p>
                  <ul className="list-disc pl-6 text-white-700 space-y-2 mt-4">
                    <li>IP address</li>
                    <li>Geolocation information</li>
                    <li>Browser type</li>
                    <li>Information about the use of our website</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl font-medium mb-3">Account Information</h3>
                  <p className="text-white-700">
                    If you create an account, we ask for information including, but not limited to:
                  </p>
                  <ul className="list-disc pl-6 text-white-700 space-y-2 mt-4">
                    <li>Your email address</li>
                    <li>Your name</li>
                    <li>The company you work for</li>
                    <li>Payment card information (for paying customers)</li>
                    <li>Links to Ads you'd like to save (when using our Chrome Extension)</li>
                  </ul>
                </div>

                <div>
                  <h3 className="text-xl font-medium mb-3">Support Communications</h3>
                  <p className="text-white-700">
                    If you reach out via email or chat, we keep conversations and other data you might send during 
                    those exchanges. When you delete your account, those conversations are removed after a period 
                    of up to 6 months.
                  </p>
                </div>
              </div>
            </section>

            {/* How We Use Your Data Section */}
            <section className="mt-12">
              <h2 className="text-2xl font-semibold mb-6">How We Use Your Data to Provide Our Services</h2>
              <div className="space-y-4">
                <p className="text-white-700">
                  We collect and process your data for the following purposes:
                </p>
                <ul className="list-disc pl-6 text-white-700 space-y-2">
                  <li>To provide access to our services as per our Terms of Service, including personalization and account management</li>
                  <li>To improve the user experience: We analyze usage patterns and various statistics</li>
                  <li>To offer customer support via email or chat</li>
                  <li>To fight fraud: We actively combat credit card fraud and misuse of our services</li>
                  <li>To market new services: We may inform users about new releases and relevant updates</li>
                </ul>
              </div>
            </section>

            {/* Your Rights Section */}
            <section className="mt-12">
              <h2 className="text-2xl font-semibold mb-6">Your Rights</h2>
              <div className="space-y-4">
                <p className="text-white-700">
                  You have the following rights regarding the personal data we hold about you:
                </p>
                <ul className="list-disc pl-6 text-white-700 space-y-2">
                  <li>Access: Right to access your personal data and obtain information about its processing</li>
                  <li>Rectification: Request correction of inaccurate data</li>
                  <li>Erasure: Request deletion of your personal data</li>
                  <li>Restriction: Request restriction of processing</li>
                  <li>Data Portability: Receive your data in a structured format</li>
                  <li>Objection and Direct Marketing: Object to data processing</li>
                  <li>Withdrawal of Consent: Withdraw your consent at any time</li>
                </ul>
              </div>
            </section>

            {/* Data Security and Retention Section */}
            <section className="mt-12">
              <h2 className="text-2xl font-semibold mb-6">Data Security and Retention</h2>
              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-medium mb-3">Security</h3>
                  <p className="text-white-700">
                    We take appropriate technical and organizational measures to ensure the security of your 
                    personal data and protect it against unauthorized access, loss, or alteration.
                  </p>
                </div>

                <div>
                  <h3 className="text-xl font-medium mb-3">Data Retention</h3>
                  <p className="text-white-700">
                    We retain your personal data for as long as necessary to fulfill the purposes outlined in 
                    this Privacy Policy. The retention period may vary depending on the type of data and legal 
                    requirements.
                  </p>
                </div>
              </div>
            </section>

            {/* Third-Party Services Section */}
            <section className="mt-12">
              <h2 className="text-2xl font-semibold mb-6">Third-Party Services and Sharing</h2>
              <div className="space-y-4">
                <p className="text-white-700">
                  When you visit our website, log in, register, or open an email, cookies, ad beacons, and 
                  similar technologies may be used by our online data partners or vendors.
                </p>
                <p className="text-white-700">
                  We may share your personal data with third parties for processing or marketing purposes, 
                  ensuring appropriate safeguards are in place to protect your data.
                </p>
              </div>
            </section>
          </div>

          {/* Contact Section */}
          <div className="border-t border-white-200">
            <div className="max-w-4xl mx-auto px-4 py-12">
              <h2 className="text-2xl font-semibold mb-4">Contact</h2>
              <p className="text-white-700">
                If you have any questions, concerns, or requests regarding this Privacy Policy or the processing 
                of your personal data, please contact us at contact@synads.co
              </p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Privacy;