import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/translations';
import logofooter from '../assets/img/logofooter.png';

const FooterColumn = ({ title, items, links = {} }) => {
    return (
        <div className="footer-column">
            <h3>{title}</h3>
            <ul>
                {Object.entries(items).map(([key, value]) => (
                    <li key={key}>
                        {links[key] ? (
                            <Link to={links[key]} className="no-underline text-inherit hover:no-underline">
                                <span>{value}</span>
                            </Link>
                        ) : (
                            <span>{value}</span>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

const Footer = () => {
    const { language } = useLanguage();
    const t = translations[language];

    const productLinks = {
        terms: '/terms-service',
        privacy:'/privacy',
        pricing: '/pricing'
    };

    const socialLinks = {
        twitter: 'https://twitter.com',
        instagram: 'https://instagram.com',
        facebook: 'https://facebook.com'
    };

    return (
        <footer className="footer">
            <div className="footer-bar">
                <div className="line"></div>
                <div className="logo2">
                    <img src={logofooter} alt={language === 'fr' ? 'Logo pied de page' : 'Footer logo'} />
                </div>
            </div>
            <div className="footer-content">
                <FooterColumn 
                    title={t.footer.product.title} 
                    items={t.footer.product.items}
                    links={productLinks}
                />
                <FooterColumn 
                    title={t.footer.socials.title} 
                    items={t.footer.socials.items}
                    links={socialLinks}
                />
                <FooterColumn 
                    title={t.footer.info.title} 
                    items={t.footer.info.items}
                />
            </div>
            <style jsx>{`
                .footer {
                    padding: 40px 0;
                    font-family: 'PoppinsMedium', sans-serif;
                }
                
                .footer-bar {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: relative;
                    width: 100%;
                    padding: 20px 0;
                }
                
                .line {
                    position: absolute;
                    top: 50%;
                    left: 0;
                    right: 0;
                    height: 1px;
                    background-color: #3a3a3a;
                }
                
                .logo2 {
                    position: relative;
                    background-color: #0F1629;
                    padding: 1px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1;
                }
                
                .logo2 img {
                    max-width: 80%;
                    max-height: 80%;
                    display: block;
                }
                
                .footer-content {
                    display: flex;
                    justify-content: space-around;
                    padding: 40px 0;
                    color: #fff;
                    max-width: 1200px;
                    margin: 0 auto;
                }
                
                .footer-column h3 {
                    font-size: 18px;
                    margin-bottom: 15px;
                    font-family: 'PoppinsBold', sans-serif;
                }
                
                .footer-column ul {
                    list-style-type: none;
                    padding: 0;
                }
                
                .footer-column li {
                    margin-bottom: 10px;
                    color: #a0a0a0;
                    transition: color 0.3s ease;
                }
                
                .footer-column li:hover {
                    color: #ffffff;
                }
                
                @media (max-width: 768px) {
                    .footer-content {
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                    }
                    
                    .footer-column {
                        margin-bottom: 30px;
                    }
                }
            `}</style>
        </footer>
    );
};

export default Footer;
