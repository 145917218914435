import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logowhite from '../assets/img/logowhite.svg';
import { useLanguage } from '../contexts/LanguageContext';
import { translations } from '../translations/translations';

function NavigationBar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { language, toggleLanguage } = useLanguage();
  const t = translations[language];

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="custom-navbar">
      <div className="navbar-container">
        <div className="left-section">
          <Link to="/" className="no-underline text-inherit hover:no-underline">
            <div className="logo">
              <img src={logowhite} alt="Logo" />
            </div>
          </Link>
          <div className="navbar-links">
            <Link to="/">
              <b>
                {t.product}
              </b>
            </Link>
            <Link to="/pricing"><b>{t.pricing}</b></Link>
            {/* <Link to="/resources"><b>{t.resources}</b></Link> */}
          </div>
        </div>
        <div className="right-section">
          <div className="hidden md:block"> {/* Ceci cache sur mobile et affiche à partir du breakpoint md */}
            <button
              onClick={toggleLanguage}
              className="language-switcher mr-4"
            >
              {language === 'fr' ? '🇫🇷' : '🇬🇧'}
            </button>
          </div>
          <div className="social-icons">
            <button 
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full transition duration-300 ease-in-out"
              onClick={() => window.open('https://app.synads.co/', '_blank', 'noopener,noreferrer')}
            >
              {t.freeTrial}
            </button>
          </div>
          <button className="hamburger" onClick={toggleMenu}>
            ☰
          </button>
        </div>
      </div>
      {isMenuOpen && (
        <div className="mobile-menu">
          <Link to="/product" onClick={toggleMenu}>{t.product}</Link>
          <Link to="/pricing" onClick={toggleMenu}>{t.pricing}</Link>
          {/* <Link to="/resources" onClick={toggleMenu}>{t.resources}</Link> */}
          <button
            onClick={toggleLanguage}
            className="language-switcher mr-4"
          >
            {language === 'fr' ? '🇫🇷' : '🇬🇧'}
          </button>
          <div className="social-icons">
            <button>Free Trial</button>
          </div>
        </div>
      )}
    </nav>
  );
}

export default NavigationBar;
