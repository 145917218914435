export const translations = {
    fr: {
        freeTrial: 'Essai gratuit',
        product: 'Produit',
        pricing: 'Tarification',
        resources: 'Ressources',
        mainTitle: {
            before: 'Lancez des',
            highlight: 'publicités',
            after: 'gagnantes'
        },
        subtitle: 'Maximisez vos revenus grâce à des publicités ciblées et efficaces.',
        spyTitle: {
            highlight: 'Espionnez',
            after: 'en 1 clic'
        },
        spySubtitle: 'Identifiez les publicités gagnantes de vos concurrents simplement à partir de leur nom de domaine',
        inspirationTitle: {
            before: "Une",
            highlight: 'inspiration publicitaire',
            after: 'sans fin'
        },
        inspirationSubtitle: 'Accédez à une vaste bibliothèque de publicités, avec des filtres alimentés par l\'IA pour trouver celles qui correspondent à votre marque et découvrez vos concurrents à travers le monde.',
        bottomSection: {
            title: {
                highlight: 'Filtres',
                after: 'avancés'
            },
            subtitle: 'Découvrez des opportunités cachées grâce à nos filtres intelligents',
            metaSection: {
                title: {
                    highlight: 'Meta',
                    after: 'ads'
                },
                description: 'Explorez et analysez les meilleures publicités Meta. Identifiez les tendances qui fonctionnent et adaptez-les à votre stratégie marketing pour des résultats optimaux.'
            }
        },
        pricingHeader: {
            title: 'Tarification',
            text: 'Choisissez le plan parfait pour vous',
            description: 'Des offres adaptées à vos besoins, de la startup ambitieuse à l\'entreprise établie'
        },
        pricingTiers: {
            hobby: {
                name: 'Entreprise',
                id: 'tier-hobby',
                priceMonthly: 'Contactez nous',
                description: 'Le plan parfait pour débuter avec notre produit.',
                features: [
                    '25 produits',
                    'Jusqu\'à 10 000 abonnés',
                    'Analyses avancées',
                    'Support sous 24h'
                ],
                featured: false
            },
            enterprise: {
                name: 'Professionnel',
                id: 'tier-enterprise',
                priceMonthly: '49.99$',
                description: 'Fonctionnalités avancées pour la veille concurrentielle et l\'inspiration publicitaire.',
                features: [
                    'Scrapping de sites web',
                    'Dossiers illimités',
                    'Partage de liens illimité',
                    'Recherche & filtrage IA',
                    'Enregistrement illimité d\'annonces',
                    'Bibliothèque croissante de +500k annonces'
                ],
                featured: true
            }
        },
        footer: {
            product: {
                title: 'Produit',
                items: {
                    pricing: 'Tarifs',
                    privacy: 'Privacy',
                    terms: 'Terms of Service'
                }
            },
            socials: {
                title: 'Réseaux sociaux',
                items: {
                    twitter: 'Twitter',
                    instagram: 'Instagram',
                    facebook: 'Facebook'
                }
            },
            info: {
                title: 'Informations',
                items: {
                    twitter: 'Twitter',
                    instagram: 'Instagram',
                    facebook: 'Facebook'
                }
            }
        },
    },


    en: {
        freeTrial:'Free Trial',
        product: 'Product',
        pricing: 'Pricing',
        resources: 'Resources',
        mainTitle: {
            before: 'Launch',
            highlight: 'winning',
            after: 'ads'
        },
        subtitle: 'Maximize your revenue with targeted and effective advertising.',
        spyTitle: {
            highlight: 'Spy',
            after: 'in 1 click'
        },
        spySubtitle: 'Identify winning ads from your competitors simply by using their domain name',
        inspirationTitle: {
            before: "Endless",
            highlight: 'advertising',
            after: 'inspiration'
        },
        inspirationSubtitle: 'Access a vast library of ads, with AI-powered filters to find ones that match your brand and discover your competitors worldwide.',
        bottomSection: {
            title: {
                highlight: 'Advanced',
                after: 'filters'
            },
            subtitle: 'Uncover hidden opportunities with our smart filters',
            metaSection: {
                title: {
                    highlight: 'Meta',
                    after: 'ads'
                },
                description: 'Explore and analyze the best Meta ads. Identify working trends and adapt them to your marketing strategy for optimal results.'
            }
        },
        pricingHeader: {
            title: 'Pricing',
            text: 'Choose the right plan for you',
            description: 'Tailored solutions for everyone, from ambitious startups to established enterprises'
        },
        pricingTiers: {
            hobby: {
                name: 'Business',
                id: 'tier-hobby',
                priceMonthly: 'Contact us',
                description: 'The perfect plan to get started with our product.',
                features: [
                    '25 products',
                    'Up to 10,000 subscribers',
                    'Advanced analytics',
                    '24-hour support'
                ],
                featured: false
            },
            enterprise: {
                name: 'Professional',
                id: 'tier-enterprise',
                priceMonthly: '$49,99',
                description: 'Advanced features for competitive intelligence and ad inspiration.',
                features: [
                    'Website scraping',
                    'Unlimited folders',
                    'Unlimited shared link times',
                    'AI Search & Filtering',
                    'Unlimited Ads Saved',
                    'Growing Library of +500k Ads'
                ],
                featured: true
            }
        },
        footer: {
            product: {
                title: 'Product',
                items: {
                    pricing: 'Pricing',
                    privacy: 'Privacy',
                    terms: 'Terms of Service'
                }
            },
            socials: {
                title: 'Socials',
                items: {
                    twitter: 'Twitter',
                    instagram: 'Instagram',
                    facebook: 'Facebook'
                }
            },
            info: {
                title: 'Information',
                items: {
                    twitter: 'Twitter',
                    instagram: 'Instagram',
                    facebook: 'Facebook'
                }
            }
        }
    }
};