import React from 'react';
import './App.css';
import HomePage from './pages/HomePage';
import TermsOfService from './pages/TermsOfService';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LanguageProvider } from './contexts/LanguageContext';
import Pricing from './pages/Pricing';
import Privacy from './pages/Privacy';

function App() {
  return (
    <LanguageProvider>
      <Router>
        <div>
          <Routes>
          <Route path="/" element={ <HomePage />} />
          <Route path="/pricing" element={ <Pricing/> } />
          <Route path="/terms-service" element={ <TermsOfService />} />
          <Route path="/privacy" element={ <Privacy />} />
          </Routes>
        </div>
      </Router>
    </LanguageProvider>
  );
}

export default App;