import React from 'react';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';

function TermsOfService() {
  return (
    <div className='bottom-div'>
      <div className='app-wrapper app-container'>
        <NavigationBar />
        <div className="min-h-screen">
          {/* Hero Section */}
          <div className="flex flex-col items-center justify-center py-12">
            <h1 className="text-4xl font-bold mb-4">Terms of Service</h1>
            <p className="text-white-600">Last updated: November 14, 2024</p>
          </div>

          {/* Main Content */}
          <div className="max-w-4xl mx-auto px-4 py-16">
            {/* Initial Warning */}
            <div className="prose max-w-none mb-12">
              <p className="text-white-700 mb-8 font-semibold">
                THE MANDATORY ARBITRATION PROVISION AND CLASS ACTION WAIVER BELOW GOVERN THE RESOLUTION OF DISPUTES. 
                PLEASE READ THEM CAREFULLY. IF YOU DO NOT AGREE WITH THE DISPUTE RESOLUTION; ARBITRATION AGREEMENT; 
                CLASS ACTION WAIVER IN THESE TERMS OF SERVICE, PLEASE DO NOT USE THE SERVICES.
              </p>
            </div>

            {/* Introduction */}
            <div className="prose max-w-none mb-12">
              <p className="text-white-700 mb-8">
                Please read these Terms of Service fully and carefully before using www.synads.co, any applications 
                provided by Synads.co and the media file storage and collaboration services, features, content or 
                applications offered by Synads.co, Inc.
              </p>
            </div>

            {/* Sections */}
            <div className="space-y-12">
              {/* Acceptance Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Acceptance of Terms of Service</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    By registering for and/or using the Services in any manner, you agree to these Terms of Service 
                    and all other operating rules, policies, and procedures that may be published from time to time 
                    on the Site by us, each of which is incorporated by reference.
                  </p>
                  <p className="text-white-700">
                    Certain Services may be subject to additional terms and conditions specified by us from time to time.
                  </p>
                </div>
              </section>

              {/* Eligibility Section */}
                <h2 className="text-2xl font-semibold mb-6">Eligibility</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    You represent and warrant that you are at least 13 years of age. If you are under age 13, you may not, 
                    under any circumstances or for any reason, use the Services.
                  </p>
                  <p className="text-white-700">
                    We may, in our sole discretion, refuse to offer the Services to any person or entity and change its 
                    eligibility criteria at any time.
                  </p>
                </div>

              {/* Registration Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Registration</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    To sign up for the Services, you must register an account. You must provide accurate and complete 
                    information and keep your Account information updated.
                  </p>
                  <p className="text-white-700">
                    You shall not:
                  </p>
                  <ul className="list-disc pl-6 text-white-700 space-y-2">
                    <li>Use the name of another person with the intent to impersonate that person</li>
                    <li>Use the name of a person other than yourself without appropriate authorization</li>
                  </ul>
                </div>
              </section>
              <section>
                <h2 className="text-2xl font-semibold mb-6">Free Memberships, Offers, or Trials</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    SynAds.co may offer free memberships, offers, and trial memberships at its sole discretion. 
                    If access to the Services is provided to you for free or for trial purposes, such access is 
                    governed by these Terms of Service.
                  </p>
                  <p className="text-white-700">
                    At any time prior to or during the free or trial period, SynAds.co may, in its sole discretion, 
                    terminate the free or trial access without prior notice and without any liability to you, to the 
                    extent permitted under applicable law.
                  </p>
                  <p className="text-white-700">
                    During the free or trial period:
                  </p>
                  <ul className="list-disc pl-6 text-white-700 space-y-2">
                    <li>No express or implied warranties shall apply to the Services</li>
                    <li>All Services are provided "as-is" with all defects</li>
                    <li>No technical or other support is included</li>
                  </ul>
                </div>
              </section>

              {/* Prerelease/Beta Version Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Prerelease or Beta Version</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    We may designate the Services, or a feature of the Services, as a prerelease or beta version 
                    ("Beta Version"). A Beta Version does not represent the final product and may contain bugs 
                    that may cause system or other failure and data loss.
                  </p>
                  <p className="text-white-700">
                    You agree that SynAds.co may collect data regarding your use of the Beta Version to improve 
                    our products and personalize your experience, regardless of whether or not you have opted out 
                    of data collection for non-Beta Versions.
                  </p>
                </div>
              </section>
              {/* Content Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Content</h2>
                <div className="space-y-6">
                  <div>
                    <h3 className="text-xl font-medium mb-3">Definition</h3>
                    <p className="text-white-700">
                      The term "Content" includes, without limitation, information, videos, data, text, photographs, 
                      software, scripts, graphics, and interactive features generated, provided, or otherwise made 
                      accessible on or through the Services.
                    </p>
                  </div>
                  
                  <div>
                    <h3 className="text-xl font-medium mb-3">User Content</h3>
                    <p className="text-white-700">
                      All Content added, created, uploaded, submitted, distributed, or posted to the Services by users, 
                      whether publicly or privately transmitted, is the sole responsibility of the person who originated 
                      such User Content.
                    </p>
                  </div>

                  <div>
                    <h3 className="text-xl font-medium mb-3">User Content License Grant</h3>
                    <p className="text-white-700">
                      By submitting User Content through the Services, you grant us a worldwide, non-exclusive, 
                      revocable, royalty-free, fully paid, sublicensable, and transferable right and license to 
                      access, display, disclose, transmit, store, and cache the User Content.
                    </p>
                  </div>
                </div>
              </section>

              {/* Team Accounts Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Team Accounts</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    If you purchase a Team Account, you have the authority to invite additional Team Members and 
                    generate share links to outside collaborators to view a designated Board.
                  </p>
                </div>
              </section>

              {/* Payments and Billing Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Payments and Billing</h2>
                <div className="space-y-6">
                  <div>
                    <h3 className="text-xl font-medium mb-3">Paid Services</h3>
                    <p className="text-white-700">
                      Certain of our Services may be subject to payments now or in the future. Any payment terms 
                      presented to you in the process of using or signing up for a Paid Service are deemed part 
                      of this Agreement.
                    </p>
                  </div>

                  <div>
                    <h3 className="text-xl font-medium mb-3">Recurring Billing</h3>
                    <p className="text-white-700">
                      WE MAY SUBMIT PERIODIC CHARGES WITHOUT FURTHER AUTHORIZATION FROM YOU, UNTIL YOU PROVIDE 
                      PRIOR NOTICE THAT YOU HAVE TERMINATED THIS AUTHORIZATION OR WISH TO CHANGE YOUR PAYMENT METHOD.
                    </p>
                  </div>
                </div>
              </section>

              {/* Affiliate Programs Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Affiliate Programs</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    To participate in Synads.co's affiliate program you agree to:
                  </p>
                  <ul className="list-disc pl-6 text-white-700 space-y-2">
                    <li>No self-referrals are allowed</li>
                    <li>No abuse or gaming of the system</li>
                    <li>No search engine ads on branded terms</li>
                    <li>No pretending to be acting on behalf of us</li>
                  </ul>
                </div>
              </section>

              {/* Warranty Disclaimer Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Warranty Disclaimer</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    THE SERVICES AND CONTENT ARE PROVIDED "AS IS", "AS AVAILABLE" AND WITHOUT WARRANTY OF ANY KIND, 
                    EXPRESS OR IMPLIED.
                  </p>
                </div>
              </section>

              {/* Limitation of Liability Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Limitation of Liability</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    IN NO EVENT SHALL WE BE LIABLE FOR ANY LOST PROFITS, DATA LOSS, OR SPECIAL, INDIRECT, INCIDENTAL, 
                    PUNITIVE, COMPENSATORY OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER.
                  </p>
                </div>
              </section>

              {/* Dispute Resolution Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Dispute Resolution</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    If you have any concern or dispute, you agree to first try to resolve the dispute informally 
                    by contacting us.
                  </p>
                </div>
              </section>
              <section>
                <h2 className="text-2xl font-semibold mb-6">Availability of Content</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    We do not guarantee that any Content will be made available on the Site or through the Services. 
                    We reserve the right to, but do not have any obligation to:
                  </p>
                  <ul className="list-disc pl-6 text-white-700 space-y-2">
                    <li>Remove, edit, or modify any Content in our sole discretion, at any time, without notice to you</li>
                    <li>Remove or block any Content from the Services</li>
                  </ul>
                </div>
              </section>

              {/* Rules of Conduct Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Rules of Conduct</h2>
                <div className="space-y-6">
                  <div>
                    <h3 className="text-xl font-medium mb-3">Overview</h3>
                    <p className="text-white-700">
                      As a user of the Services, you agree to not engage in any prohibited actions or activities 
                      that violate these Terms of Service or applicable laws.
                    </p>
                  </div>

                  <div>
                    <h3 className="text-xl font-medium mb-3">Prohibited Activities</h3>
                    <p className="text-white-700 mb-4">Include but are not limited to:</p>
                    <ul className="list-disc pl-6 text-white-700 space-y-2">
                      <li>Content Violations: Actions or content that violate these Terms, laws, or third-party rights</li>
                      <li>Impersonation: Pretending to be someone else, including service employees</li>
                      <li>System Interference: Placing excessive demands on the service's infrastructure</li>
                      <li>Using automated means (bots, scrapers) to access or collect data</li>
                    </ul>
                  </div>
                </div>
              </section>

              {/* Ownership Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Ownership</h2>
                <div className="space-y-6">
                  <div>
                    <h3 className="text-xl font-medium mb-3">User Content</h3>
                    <p className="text-white-700">
                      You retain full ownership and rights to any content you create or upload (referred to as 
                      "User Content"). Synads.co does not claim ownership over your User Content.
                    </p>
                  </div>

                  <div>
                    <h3 className="text-xl font-medium mb-3">Synads.co Intellectual Property</h3>
                    <p className="text-white-700">
                      Synads.co (along with any licensors) retains all ownership rights to the Services, including 
                      any associated intellectual property like patents, copyrights, trade secrets, and trademarks.
                    </p>
                  </div>
                </div>
              </section>
              <section>
                <h2 className="text-2xl font-semibold mb-6">Third-Party Services</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    The Services may permit you to link to other websites, services or resources on the Internet, 
                    and other websites, services or resources may contain links to the Services.
                  </p>
                  <p className="text-white-700">
                    When you access third party resources on the Internet, you do so at your own risk. These other 
                    resources are not under our control, and you acknowledge that we are not responsible or liable for:
                  </p>
                  <ul className="list-disc pl-6 text-white-700 space-y-2">
                    <li>The content, functions, accuracy, legality, appropriateness of such websites</li>
                    <li>Any damage or loss caused by or in connection with use of such content</li>
                  </ul>
                </div>
              </section>

              {/* Detailed Payments and Billing Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Payments and Billing</h2>
                <div className="space-y-6">
                  <div>
                    <h3 className="text-xl font-medium mb-3">Taxes and Third-Party Fees</h3>
                    <p className="text-white-700">
                      You must pay any applicable taxes and third-party fees (including, for example, telephone 
                      charges, mobile carrier fees, ISP charges, data plan charges, credit card fees, foreign 
                      exchange fees).
                    </p>
                  </div>

                  <div>
                    <h3 className="text-xl font-medium mb-3">Billing</h3>
                    <p className="text-white-700">
                      We use a third-party payment processor, Stripe, to bill you through a payment account linked 
                      to your Account on the Services. The processing of payments will be subject to the terms, 
                      conditions and privacy policies of the Payment Processor.
                    </p>
                  </div>

                  <div>
                    <h3 className="text-xl font-medium mb-3">Payment Method</h3>
                    <p className="text-white-700">
                      If we, through the Payment Processor, do not receive payment from you, you agree to pay all 
                      amounts due on your Billing Account upon demand.
                    </p>
                  </div>

                  <div>
                    <h3 className="text-xl font-medium mb-3">Auto-Renewal for Subscription Services</h3>
                    <p className="text-white-700">
                      Any Subscription Services you have signed up for will be automatically extended for successive 
                      renewal periods of the same duration as the subscription term originally selected, at the 
                      then-current non-promotional rate.
                    </p>
                  </div>
                </div>
              </section>

              {/* Arbitration Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Dispute Resolution; Arbitration Agreement</h2>
                <div className="space-y-6">
                  <div>
                    <h3 className="text-xl font-medium mb-3">Notice of Claim</h3>
                    <p className="text-white-700">
                      If you have any concern or dispute that Synads.co Support is unable to resolve, you agree to 
                      first try to resolve the dispute informally by contacting us and providing a written Notice 
                      of Claim.
                    </p>
                  </div>

                  <div>
                    <h3 className="text-xl font-medium mb-3">Location</h3>
                    <p className="text-white-700">
                      The arbitration will take place at any reasonable location within the United States or Canada 
                      reasonably convenient for both parties.
                    </p>
                  </div>

                  <div>
                    <h3 className="text-xl font-medium mb-3">No Class Actions</h3>
                    <p className="text-white-700">
                      You may only resolve disputes with us on an individual basis, and you may not bring a claim 
                      as a plaintiff or a class member in a class, consolidated, or representative action.
                    </p>
                  </div>
                </div>
              </section>

              {/* Modification Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Modification</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    We reserve the right, in our sole discretion, to modify or replace any of these Terms of Service, 
                    or change, suspend, or discontinue the Services at any time by posting a notice on the Site or 
                    by sending you notice through the Services.
                  </p>
                  <p className="text-white-700">
                    Your continued use of the Services following notification of any changes to these Terms of 
                    Service constitutes acceptance of those changes.
                  </p>
                </div>
              </section>

              {/* Miscellaneous Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Miscellaneous</h2>
                <div className="space-y-6">
                  <div>
                    <h3 className="text-xl font-medium mb-3">Entire Agreement</h3>
                    <p className="text-white-700">
                      These Terms of Service are the entire agreement between you and us with respect to the 
                      Services and supersede all prior or contemporaneous communications and proposals.
                    </p>
                  </div>

                  <div>
                    <h3 className="text-xl font-medium mb-3">Force Majeure</h3>
                    <p className="text-white-700">
                      We shall not be liable for any failure to perform our obligations hereunder where such 
                      failure results from any cause beyond our reasonable control.
                    </p>
                  </div>

                  <div>
                    <h3 className="text-xl font-medium mb-3">Assignment</h3>
                    <p className="text-white-700">
                      These Terms of Service are personal to you, and are not assignable, transferable or 
                      sublicensable by you except with our prior written consent.
                    </p>
                  </div>
                </div>
              </section>
              <section>
                <h2 className="text-2xl font-semibold mb-6">Detailed Warranty Disclaimer</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    We have no special relationship with or fiduciary duty to you. You acknowledge that we have 
                    no duty to take any action regarding:
                  </p>
                  <ul className="list-disc pl-6 text-white-700 space-y-2">
                    <li>Which users gain access to the Services</li>
                    <li>What Content you access via the Services</li>
                    <li>How you may interpret or use the Content</li>
                  </ul>
                  <p className="text-white-700 mt-4 font-semibold">
                    THE SERVICES AND CONTENT ARE PROVIDED "AS IS", "AS AVAILABLE" AND WITHOUT WARRANTY OF ANY KIND, 
                    EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO:
                  </p>
                  <ul className="list-disc pl-6 text-white-700 space-y-2">
                    <li>The implied warranties of title, non-infringement, merchantability and fitness for a particular purpose</li>
                    <li>That the Services will be secure or available at any particular time or location</li>
                    <li>That any defects or errors will be corrected</li>
                    <li>That the content will be secure or not otherwise lost or damaged</li>
                    <li>That the Services will not harm your computer system</li>
                  </ul>
                </div>
              </section>

              {/* Indemnification Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Indemnification</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    You shall defend, indemnify, and hold harmless us, our affiliates and each of our and their 
                    respective employees, contractors, directors, suppliers and representatives from all liabilities, 
                    claims, and expenses, including reasonable attorneys' fees, that arise from or relate to:
                  </p>
                  <ul className="list-disc pl-6 text-white-700 space-y-2">
                    <li>Your use or misuse of the Services</li>
                    <li>Your violation of these Terms of Service</li>
                    <li>Your User Content</li>
                    <li>Infringement by you, or any third party using your Account</li>
                  </ul>
                  <p className="text-white-700 mt-4">
                    We reserve the right to assume the exclusive defense and control of any matter otherwise subject 
                    to indemnification by you, in which event you will assist and cooperate with us in asserting 
                    any available defenses.
                  </p>
                </div>
              </section>

              {/* Detailed Limitation of Liability Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Detailed Limitation of Liability</h2>
                <div className="space-y-4">
                  <p className="text-white-700 font-semibold">
                    IN NO EVENT SHALL WE, NOR OUR DIRECTORS, EMPLOYEES, AFFILIATES, AGENTS, PARTNERS, SUPPLIERS OR 
                    CONTENT PROVIDERS, BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE, WARRANTY OR 
                    ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICES FOR:
                  </p>
                  <ul className="list-disc pl-6 text-white-700 space-y-2">
                    <li>Any lost profits, data loss, cost of procurement of substitute goods or services</li>
                    <li>Special, indirect, incidental, punitive, compensatory or consequential damages of any kind whatsoever</li>
                    <li>Damages for lost profits, revenue, goodwill, use or content</li>
                    <li>Bugs, viruses, trojan horses, or the like</li>
                    <li>Direct damages in excess of the greater of $500.00 or the fees paid to us for the particular 
                        services during the immediately previous three (3) month period</li>
                  </ul>
                </div>
              </section>

              {/* Termination Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Termination</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    We may terminate your access to all or any part of the Services at any time, with or without 
                    cause, with or without notice, effective immediately, which may result in the forfeiture and 
                    destruction of all information associated with your membership.
                  </p>
                  <p className="text-white-700">
                    If you wish to terminate your Account, you may do so by contacting Synads via in platform chat 
                    or email contact@synads.co. Any fees paid hereunder are non-refundable.
                  </p>
                  <p className="text-white-700">
                    All provisions of these Terms of Service which by their nature should survive termination shall 
                    survive termination, including, without limitation:
                  </p>
                  <ul className="list-disc pl-6 text-white-700 space-y-2">
                    <li>Licenses of User Content</li>
                    <li>Ownership provisions</li>
                    <li>Warranty disclaimers</li>
                    <li>Indemnity and limitations of liability</li>
                  </ul>
                </div>
              </section>

              {/* Contact Information Section */}
              <section>
                <h2 className="text-2xl font-semibold mb-6">Contact Information</h2>
                <div className="space-y-4">
                  <p className="text-white-700">
                    For any questions about these Terms of Service or any issues:
                  </p>
                  <ul className="list-disc pl-6 text-white-700 space-y-2">
                    <li>Contact us via the in-app messenger</li>
                    <li>Email us at contact@synads.co</li>
                  </ul>
                </div>
              </section>

            </div>
          </div>

          {/* Contact Section */}
          <div className="border-t border-white-200">
            <div className="max-w-4xl mx-auto px-4 py-12">
              <h2 className="text-2xl font-semibold mb-4">Contact</h2>
              <p className="text-white-700">
                For any questions regarding these Terms of Service, you can contact us at contact@synads.co
              </p>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    </div>
  );
}

export default TermsOfService;