import React from 'react';
import dashboardImage from '../assets/img/dashboardImage.png';
import dashboardImage2 from '../assets/img/dashboardImage2.png';
import NavigationBar from '../components/Navbar';
import Footer from '../components/Footer';
import logoblack from '../assets/img/logoblack.png';
import overview from '../assets/img/overviewscreen.png'


import metaads from '../assets/img/metaads.png';
import googleads from '../assets/img/googleads.png'
import tiktokads from '../assets/img/tiktokads.png'
import spylogo from '../assets/img/spylogoblue.svg';


import saveadsbrand from '../assets/img/saveadsbrand.png';
import screenapp2 from '../assets/img/screenapp2.png'
import { CheckIcon } from '@heroicons/react/20/solid'
import { translations } from '../translations/translations';
import { useLanguage } from '../contexts/LanguageContext';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}


function Pricing() {
    const { language } = useLanguage();
    const t = translations[language];

    return (
        <div >
            <div className='bottom-div'>
                <div className='app-wrapper app-container'>
                    <NavigationBar />

                    <main className="main-content">
                        <h1 className='poppins'>
                            <span className='linear-color-title'>
                                {t.pricingHeader.title}
                            </span>
                        </h1>
                        <p className="mx-auto mt-6 max-w-2xl text-pretty text-center text-lg font-medium text-white-600 sm:text-xl/8">
                            {t.pricingHeader.description}
                        </p>
                        
                        
                        <div className="relative isolate">
                      

                        <div className="mx-auto  grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2">
                            <div className="bg-white/90 sm:mx-8 lg:mx-0 rounded-t-3xl sm:rounded-b-none lg:rounded-bl-3xl lg:rounded-tr-none rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10">
                                <h3 id="tier-hobby" className="text-indigo-600 text-base/7 font-semibold">
                                    {t.pricingTiers?.hobby.name}
                                </h3>
                                <p className="mt-4 flex w-full justify-center items-center">
    <span className="text-gray-900 text-4xl font-semibold tracking-tight text-center">
        {t.pricingTiers?.hobby.priceMonthly}
    </span>
</p>
                                <p className="text-gray-600 mt-6 text-base/7">
                                    {t.pricingTiers?.hobby.description}
                                </p>
                                <ul role="list" className="text-gray-600 mt-8 space-y-3 text-sm/6 sm:mt-10">
                                    {t.pricingTiers?.hobby.features.map((feature) => (
                                        <li key={feature} className="flex gap-x-3">
                                            <CheckIcon
                                                aria-hidden="true"
                                                className="text-indigo-600 h-6 w-5 flex-none"
                                            />
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                                <a
                                    href="https://app.synads.co/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-describedby="tier-hobby"
                                    className="no-underline hover:no-underline text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300 focus-visible:outline-indigo-600 mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold sm:mt-10"
                                >
                                    {language === 'fr' ? 'Contactez nous' : 'Contact us'}
                                </a>
                            </div>

                            {/* Enterprise Tier */}
                            <div className="relative bg-gray-900 shadow-2xl sm:rounded-t-none lg:rounded-bl-none lg:rounded-tr-3xl rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10">
                                <h3 id="tier-enterprise" className="text-indigo-400 text-base/7 font-semibold">
                                    {t.pricingTiers?.enterprise.name}
                                </h3>
                                <p className="mt-4 flex items-baseline gap-x-2">
                                    <span className="text-white text-5xl font-semibold tracking-tight">
                                        {t.pricingTiers?.enterprise.priceMonthly}
                                    </span>
                                    <span className="text-gray-400 text-base">
                                        {language === 'fr' ? '/mois' : '/month'}
                                    </span>
                                </p>
                                <p className="text-gray-300 mt-6 text-base/7">
                                    {t.pricingTiers?.enterprise.description}
                                </p>
                                <ul role="list" className="text-gray-300 mt-8 space-y-3 text-sm/6 sm:mt-10">
                                    {t.pricingTiers?.enterprise.features.map((feature) => (
                                        <li key={feature} className="flex gap-x-3">
                                            <CheckIcon
                                                aria-hidden="true"
                                                className="text-indigo-400 h-6 w-5 flex-none"
                                            />
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                                <a
                                    href="https://app.synads.co/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    aria-describedby="tier-enterprise"
                                    className="no-underline hover:no-underline bg-indigo-500 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline-indigo-500 mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold sm:mt-10"
                                >
                                    {t.freeTrial}
                                </a>
                            </div>
                        </div>
                    </div>
                    </main>
                    <Footer/>
                </div>

            </div>

        </div>
    );
}

export default Pricing;
